html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
}

.Page {
}

.Header {
}

.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.7rem;
  color: #787878;
  background-color: #ffffff;
}

.navbar {
  padding: 0.5rem;
}

.nav-link {
  cursor: pointer;
}
