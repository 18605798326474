@import '../_variables.scss';

.Container {
  color: #000000;
  text-shadow: 1px 0 0 #ffffff, 0 -1px 0 #ffffff, 0 1px 0 #ffffff, -1px 0 0 #ffffff;

  @media (min-width: $breakpoint-tablet) {
    min-width: 620px;
  }
}

.Container::before{
  content: '';
  background-image: url('../images/bee-background.jpg');
  opacity: 0.4;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: -3;
  margin: 80px 16px;
  border-radius: 6px;
}

.Countdown {
  text-transform: uppercase;
  font-weight: bold;

  span {
    margin-block-start: 0.8rem;
  }

  span:first-of-type {
    margin-block-start: 0;
  }
}

.CountdownGroup {
  display: inline-block;
  padding-right: 1rem;
}

.Rounded {
  border-radius: 1rem !important;
}

.btn-demo {
  padding: 0.5rem 2rem !important;
  border-radius: 30rem !important;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-transform: uppercase;
  font-weight: bold !important;
}

.btn-demo:hover,
.btn-demo:focus {
  color: #fff;
  background: rgba(255, 255, 255, 0.5);
}

