.Container {
  position: relative;
  min-height: 100vh;
  display: flex;
}

.Carousel {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > div > div:first-child {
    height: 100%;
  }
}

.BackgroundImage {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Content {
  position: relative;
  padding: 80px 16px 80px;
  z-index: 1;
  margin: auto;
}

:global {
  .flickity-enabled {
    position: relative;
    height: 100%;
  }

  .flickity-enabled:focus {
    outline: 0;
  }

  .flickity-viewport {
    overflow: hidden;
    width: 100%;
  }

  .carousel-cell {
    width: 100%;
  }
}
