@import '../../../_variables.scss';

.Surround {
  border-radius: 6px;
  box-shadow: 0 0 16px 16px rgba(0, 0, 0, 0.16);
  padding: 24px;
  @media (min-width: $breakpoint-tablet) {
    padding: 30px;
  }
}
